import React, { useContext, createContext, useMemo, useState } from 'react'

const UnitContext = createContext()

function useUnit() {
    const context = useContext(UnitContext)
    if (!context) {
        throw new Error(`useUnit must be used within a UnitProvider`)
    }
    return context
}

function UnitProvider(props) {
    const [state, setState] = useState("FSM1M_7111")
    const value = useMemo(() => [state, setState], [state])
    return <UnitContext.Provider value={value} {...props} />
}

export { useUnit, UnitProvider }
