import React, { useContext, createContext, useMemo, useState } from 'react'

const CountryContext = createContext()

function useCountry() {
    const context = useContext(CountryContext)
    if (!context) {
        throw new Error(`useCountry must be used within a CountryProvider`)
    }
    return context
}

function CountryProvider(props) {
    const [state, setState] = useState("")
    const value = useMemo(() => [state, setState], [state])
    return <CountryContext.Provider value={value} {...props} />
}

export { useCountry, CountryProvider }
