import React, { useContext, createContext, useMemo, useState } from 'react'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'

import { DATE_FORMAT } from '../utils'

const DateRangeContext = createContext()

function useDateRange() {
  const context = useContext(DateRangeContext)
  if (!context) {
    throw new Error(`useDateRange must be used within a DateRangeProvider`)
  }
  return context
}

function DateRangeProvider(props) {
  const [state, setState] = useState({
    startDate: format(addDays(new Date(), -7), DATE_FORMAT),
    endDate: format(new Date(), DATE_FORMAT),
  })
  const value = useMemo(() => [state, setState], [state])
  return <DateRangeContext.Provider value={value} {...props} />
}

export { DateRangeProvider, useDateRange }
