import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
// import * as Sentry from "@sentry/react";

import './index.css'
import App from './App'
import file from '../package.json'

const { version } = file
console.log(version)
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://474ab0cfb35e4279a013a0829f8789d4@o4505192185790464.ingest.sentry.io/4505194115825664",
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: version,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// // Sentry.init({
// //   dsn: "https://474ab0cfb35e4279a013a0829f8789d4@o4505192185790464.ingest.sentry.io/4505194115825664",
// //   integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

// //   // Set tracesSampleRate to 1.0 to capture 100%
// //   // of transactions for performance monitoring.
// //   // We recommend adjusting this value in production
// //   tracesSampleRate: 1.0,

// //   // Capture Replay for 10% of all sessions,
// //   // plus for 100% of sessions with an error
// //   replaysSessionSampleRate: 0.1,
// //   replaysOnErrorSampleRate: 1.0,
// // });

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// reportWebVitals();
